:root {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

body {
  margin: 0;
  object-position: 50% 50%;
}
